<template>
  <div class="figure__wrapper" :class="{ 'figure__wrapper-details': isDetails }">
    <svg
      :width="defaultSvgParams.width * figureSvgScale"
      :height="normalizedSchemeHeight * figureSvgScale"
      :viewBox="`0 0 ${defaultSvgParams.width * figureSvgScale}
           ${normalizedSchemeHeight * figureSvgScale}`"
      ref="figure"
      :style="{ transform: `matrix(${zoom},0,0,${zoom},0,0)` }"
    >
      <!-- <defs v-if="!isNav">
        <filter x="-25%" y="-30%" width="1.5" height="1.6" id="text-bg">
          <feFlood flood-color="#fff" result="bg" />
          <feMerge>
            <feMergeNode in="bg" />
            <feMergeNode in="SourceGraphic" />
            <feMorphology in="SourceAlpha" operator="dilate" radius="4" />
          </feMerge>
        </filter>
      </defs>-->
      <ruler
        :x1="0"
        :y1="changeHeightDown + changeBias / 2"
        :x2="defaultSvgParams.width"
        :y2="changeHeightDown + changeBias / 2"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="changeHeightDown - changeBias / 2"
        :x2="defaultSvgParams.width"
        :y2="changeHeightDown - changeBias / 2"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthLeft"
        :y1="0"
        :x2="changeWidthLeft"
        :y2="normalizedSchemeHeight"
        v-if="!isNav"
      />
      <ruler
        :x1="changeWidthRight"
        :y1="0"
        :x2="changeWidthRight"
        :y2="normalizedSchemeHeight"
        v-if="!isNav"
      />
      <ruler
        :x1="0"
        :y1="valleyY"
        :x2="defaultSvgParams.width"
        :y2="valleyY"
        :color="isDetails ? colors.grey : colors.blue"
        v-if="!isNav && isOffsetValleyNeed"
      />
      <size-arrow :path="arrowWidth" :is-details="isDetails" color="yellow" v-if="!isNav" />
      <size-arrow :path="arrowBiasHeight" :is-details="isDetails" color="orange" v-if="!isNav" />
      <size-arrow :path="arrowVertical" :is-details="isDetails" color="grey" v-if="!isNav" />
      <size-arrow :path="arrowHorizontalLeft" :is-details="isDetails" color="grey" v-if="!isNav" />
      <size-arrow :path="arrowHorizontalRight" :is-details="isDetails" color="grey" v-if="!isNav" />
      <size-arrow
        :path="arrowValleyOffset"
        :is-details="isDetails"
        color="blue"
        v-if="!isNav && isOffsetValleyNeed"
      />
      <size
        v-if="!isNav"
        :x="changeWidthLeft - 30"
        :y="sideASizeY"
        :text="`A = ${cathetHeight}`"
        :fill="colors.grey"
      />
      <size
        v-if="!isNav"
        :x="sideBLeftSizeX"
        :y="changeHeightDown + changeBias / 2 + 30"
        :text="`B = ${slopeParams.width / 2}`"
        :fill="colors.grey"
      />
      <size
        v-if="!isNav"
        :x="sideBRightSizeX"
        :y="changeHeightDown + changeBias / 2 + 30"
        :text="`B = ${slopeParams.width / 2}`"
        :fill="colors.grey"
      />
      <size
        v-if="!isNav"
        :x="changeWidthLeft - 30"
        :y="changeHeightDown"
        :text="`C = ${slopeParams.bias1}`"
        :fill="isDetails ? colors.grey : colors.orange"
      />
      <size
        v-if="!isNav"
        :x="(defaultSvgParams.width * this.figureSvgScale) / 2"
        :y="changeHeightDown + changeBias / 2 + 60"
        :text="`D = ${slopeParams.width}`"
        :fill="isDetails ? colors.grey : colors.yellow"
      />
      <size
        v-if="!isNav && isOffsetValleyNeed"
        :x="changeWidthLeft - 30"
        :y="valleyY - (valleyY - (changeHeightDown + changeBias / 2)) / 2"
        :text="`E = ${slopeParams.offsetValley}`"
        :fill="isDetails ? colors.grey : colors.blue"
      />
      <defs v-if="isPlate">
        <clipPath :id="`figureMaskRight--sector-${slopeFromState.uid}`">
          <path
            :d="figureMaskRight"
            fill="none"
            :stroke="figureStrokeColor"
            :stroke-width="strokeWidth"
            style="stroke-linecap: round"
          ></path>
          // figure mask right
        </clipPath>
        <clipPath :id="`figureMaskLeft--sector-${slopeFromState.uid}`">
          <path
            :d="figureMaskLeft"
            fill="none"
            :stroke="figureStrokeColor"
            :stroke-width="strokeWidth"
            style="stroke-linecap: round"
          ></path>
          // figure mask left
        </clipPath>
      </defs>
      <g v-if="isDetails" name="rowsForTooltip">
        <g v-if="isDetails" :clip-path="`url(#figureMaskRight--sector-${slopeFromState.uid})`">
          <!--clip-path="url(#figureTopMaskLeft)"-->
          <g
            :transform="`rotate(-${platesRotationDegree}
              ${changeWidthRight} ${changeHeightDown - changeBias / 2})`"
          >
            <g v-for="i in rowsCount" :key="i" class="row__overlay">
              <rect
                :x="changeWidthRight - plateWidth * i"
                :y="
                  i % 2 === 0
                    ? changeHeightDown + changeBias / 2 - rowHeight + shiftToPlate - plateHeight / 2
                    : changeHeightDown + changeBias / 2 - rowHeight + shiftToPlate
                "
                :width="plateWidth - 1"
                :height="i % 2 === 0 ? rowHeight + plateHeight : rowHeight"
                fill="#F6F6F6"
                stroke="#999999"
                stroke-width="1"
                @mouseover="showRowInfo(i)"
                @mouseout="hideRowInfo()"
              ></rect>
              <line
                :x1="changeWidthRight - plateWidth * i"
                :y1="
                  i % 2 === 0
                    ? changeHeightDown +
                      changeBias / 2 -
                      rowHeight +
                      shiftToPlate +
                      plateHeight * n -
                      plateHeight / 2
                    : changeHeightDown + changeBias / 2 - rowHeight + shiftToPlate + plateHeight * n
                "
                :x2="changeWidthRight - plateWidth * i + plateWidth - 1"
                :y2="
                  i % 2 === 0
                    ? changeHeightDown +
                      changeBias / 2 -
                      rowHeight +
                      shiftToPlate +
                      plateHeight * n -
                      plateHeight / 2
                    : changeHeightDown + changeBias / 2 - rowHeight + shiftToPlate + plateHeight * n
                "
                stroke="#999999"
                stroke-width="1"
                v-for="n in i % 2 === 0 ? itemPerRow : itemPerRow - 1"
                :key="n"
              ></line>
              <!--(plateHeight * n + (plateHeight / 2)) + shift-->
            </g>
          </g>
          <g
            :transform="`rotate(${-platesRotationDegree}
            ${(defaultSvgParams.width * figureSvgScale) / 2} ${changeHeightDown + changeBias / 2})`"
          >
            <!--
          :transform="`rotate(${-platesRotationDegree}
            ${(defaultSvgParams.width * figureSvgScale) / 2} ${changeHeightDown + changeBias / 2})`"
          -->
            <row-number
              :opts="getRowNumberOpts(i, false)"
              v-for="i in rowsCount"
              :key="`right-row-number--${i}`"
            />
          </g>
        </g>
        <g v-if="isDetails" :clip-path="`url(#figureMaskLeft--sector-${slopeFromState.uid})`">
          <!--clip-path="url(#figureMaskLeft)"-->
          <g
            :transform="`rotate(${platesRotationDegree}
              ${changeWidthLeft} ${changeHeightDown - changeBias / 2})`"
          >
            <g v-for="i in rowsCount" :key="i" class="row__overlay">
              <rect
                :x="changeWidthLeft + plateWidth * (i - 1) + 1"
                :y="
                  i % 2 === 0
                    ? changeHeightDown + changeBias / 2 - rowHeight + shiftToPlate - plateHeight / 2
                    : changeHeightDown + changeBias / 2 - rowHeight + shiftToPlate
                "
                :width="plateWidth - 1"
                :height="i % 2 === 0 ? rowHeight + plateHeight : rowHeight"
                fill="#F6F6F6"
                stroke="#999999"
                stroke-width="1"
                @mouseover="showRowInfo(i)"
                @mouseout="hideRowInfo()"
              ></rect>
              <line
                :x1="changeWidthLeft + plateWidth * (i - 1) + 1"
                :y1="
                  i % 2 === 0
                    ? changeHeightDown +
                      changeBias / 2 -
                      rowHeight +
                      shiftToPlate +
                      plateHeight * n -
                      plateHeight / 2
                    : changeHeightDown + changeBias / 2 - rowHeight + shiftToPlate + plateHeight * n
                "
                :x2="changeWidthLeft + plateWidth * (i - 1) + plateWidth"
                :y2="
                  i % 2 === 0
                    ? changeHeightDown +
                      changeBias / 2 -
                      rowHeight +
                      shiftToPlate +
                      plateHeight * n -
                      plateHeight / 2
                    : changeHeightDown + changeBias / 2 - rowHeight + shiftToPlate + plateHeight * n
                "
                stroke="#999999"
                stroke-width="1"
                v-for="n in i % 2 === 0 ? itemPerRow : itemPerRow - 1"
                :key="n"
              ></line>
              <!--(plateHeight * n + (plateHeight / 2)) + shift-->
            </g>
          </g>
          <g
            :transform="`rotate(${platesRotationDegree}
            ${(defaultSvgParams.width * figureSvgScale) / 2} ${changeHeightDown + changeBias / 2})`"
          >
            <!--
          :transform="`rotate(${platesRotationDegree}
            ${(defaultSvgParams.width * figureSvgScale) / 2} ${changeHeightDown + changeBias / 2})`"
          -->
            <row-number
              :opts="getRowNumberOpts(i, true)"
              v-for="i in rowsCount"
              :key="`left-row-number--${i}`"
            />
          </g>
        </g>
      </g>
      <size-arrow
        :path="arrowDirectionRight"
        :is-details="isDetails"
        :color="arrowStrokeColor"
        :stroke-width="arrowDirectionStrokeWidth"
        :stroke-dasharray="isDetails ? 20 : 0"
        :opacity="isDetails ? 0.7 : 1"
        :transform="`rotate(${arrowDirectionRightRotationDegree}
            ${arrowRightLength - arrowBias} ${arrowDirectionY})`"
      />
      <size-arrow
        :path="arrowDirectionLeft"
        :is-details="isDetails"
        :color="arrowStrokeColor"
        :stroke-width="arrowDirectionStrokeWidth"
        :stroke-dasharray="isDetails ? 20 : 0"
        :opacity="isDetails ? 0.7 : 1"
        :transform="`rotate(${arrowDirectionLeftRotationDegree}
            ${arrowLeftLength + arrowBias} ${arrowDirectionY})`"
      />
      <path
        :d="doubleSideRectangularTrapezoidTriangleVertical"
        fill="none"
        class="scheme-circuit"
        :stroke="figureStrokeColor"
        :stroke-width="strokeWidth"
        style="stroke-linecap: round"
      ></path>
    </svg>
  </div>
</template>

<script>
import RowNumber from '@/components/figure_elements/RowNumber'
import {
  getRowNumberFontSize,
  getRowNumberXForTriangles,
  colors,
  drawSizeArrowVertical,
  drawSizeArrowHorizontal,
  getCathetSize,
  drawArrowDirection,
  getValleyY
} from '@/utils/drawing'
import Ruler from '@/components/figure_elements/Ruler'
import SizeArrow from '@/components/figure_elements/SizeArrow'
import Size from '@/components/figure_elements/Size'
import { getMin } from '@/utils'

export default {
  props: {
    isNav: Boolean,
    defaultSvgParams: Object,
    isDetails: Boolean,
    isOffsetValleyNeed: {
      type: Boolean
    },
    isPlate: Boolean,
    navId: Number,
    zoom: Number,
    rowId: {
      type: Number,
      default: 0
    },
    isForTotalPdf: {
      type: Boolean
    },
    sector: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    Size,
    SizeArrow,
    Ruler,
    RowNumber
  },
  methods: {
    getRowNumberOpts(i, isLeft) {
      const { plateWidth, scale } = this
      return {
        fontSize: getRowNumberFontSize(this.plateWidth),
        x: getRowNumberXForTriangles(
          (this.defaultSvgParams.width * this.figureSvgScale) / 2,
          plateWidth,
          i,
          (this.slopeFromState.slopeParams[0].width / 2 / this.slopeFromState.slopeParams[0].ratio +
            this.slopeFromState.slopeParams[0].bias1) *
            this.slopeFromState.slopeParams[0].ratio,
          scale,
          this.slopeParams.ratio,
          isLeft
        ),
        y: this.changeHeightDown + this.changeBias / 2,
        text: i,
        isLeft
      }
    },
    showRowInfo(i) {
      if (this.rowId !== i) {
        this.$emit('updateRowId', i)
      }
    },
    hideRowInfo() {
      if (this.rowId !== 0) {
        this.$emit('updateRowId', 0)
      }
    }
  },
  computed: {
    colors: () => colors,
    slopeFromState() {
      if (this.isForTotalPdf) {
        return this.sector
      } else {
        return (!this.isNav && !this.isDetails) || (this.isDetails && this.isPlate)
          ? this.$store.getters.getSlope(this.slopeId)
          : this.$store.getters.getSlope(this.navId)
      }
    },
    arrowVertical() {
      const { changeHeightUp, changeHeightDown, changeWidthLeft, changeBias } = this
      const x = changeWidthLeft - 30
      const y1 = changeHeightUp - changeBias / 2
      const y2 = changeHeightDown - changeBias / 2
      return drawSizeArrowVertical(x, y1, y2)
    },
    arrowHorizontalLeft() {
      const { changeHeightDown, changeBias, changeWidthLeft } = this
      const x2 = this.defaultSvgParams.width / 2
      const y = changeHeightDown + changeBias / 2 + 30
      return drawSizeArrowHorizontal(changeWidthLeft, y, x2)
    },
    arrowHorizontalRight() {
      const { changeHeightDown, changeBias, changeWidthRight } = this
      const x1 = this.defaultSvgParams.width / 2
      const y = changeHeightDown + changeBias / 2 + 30
      return drawSizeArrowHorizontal(x1, y, changeWidthRight)
    },
    sideBLeftSizeX() {
      const { changeWidthLeft, defaultSvgParams, figureSvgScale } = this
      const center = (defaultSvgParams.width * figureSvgScale) / 2
      return changeWidthLeft + (center - changeWidthLeft) / 2
    },
    sideBRightSizeX() {
      const { changeWidthRight, defaultSvgParams, figureSvgScale } = this
      const center = (defaultSvgParams.width * figureSvgScale) / 2
      return center + (changeWidthRight - center) / 2
    },
    sideASizeY() {
      const { changeHeightUp, changeHeightDown, changeBias } = this
      const y1 = changeHeightUp - changeBias / 2
      const y2 = changeHeightDown - changeBias / 2
      return y1 + (y2 - y1) / 2
    },
    slopeId() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    allSlopes() {
      return this.$store.getters.allSlopes
    },
    slopeParams() {
      return this.slopeFromState.slopeParams[0]
    },
    halfPlate() {
      return this.columnsCount % 2 === 0
        ? 0
        : (this.slopeFromState.materialSize.height / 1000 / 2) * this.scale
    },
    rowsCount() {
      return Math.ceil(
        ((this.slopeFromState.slopeParams[0].width / this.slopeFromState.slopeParams[0].ratio +
          this.slopeFromState.slopeParams[0].bias1) *
          1000) /
          this.slopeFromState.materialSize.width
      )
    },
    itemPerRow() {
      return (
        Math.ceil(
          (Math.sqrt(this.newCathetHeight ** 2 + this.newCathetWidth ** 2) * this.scale) /
            ((this.slopeFromState.materialSize.height / 1000) * this.scale)
        ) + 1
      )
    },
    newCathetHeight() {
      // return (
      //   (this.slopeFromState.slopeParams[0].width / this.slopeFromState.slopeParams[0].ratio +
      //     this.slopeFromState.slopeParams[0].bias1) /
      //   2
      // )
      return (
        this.slopeFromState.slopeParams[0].width / 2 / this.slopeFromState.slopeParams[0].ratio +
        this.slopeFromState.slopeParams[0].bias1
      )
    },
    newCathetWidth() {
      return this.newCathetHeight * this.slopeFromState.slopeParams[0].ratio
    },
    shiftToPlate() {
      return (
        (Math.ceil((this.slopeFromState.slopeParams[0].bias1 * this.scale) / this.plateHeight) -
          (this.slopeFromState.slopeParams[0].bias1 * this.scale) / this.plateHeight) *
        this.plateHeight
      )
    },
    plateHeight() {
      return (this.slopeFromState.materialSize.height / 1000) * this.scale
    },
    plateWidth() {
      return (this.slopeFromState.materialSize.width / 1000) * this.scale
    },
    rowHeight() {
      return this.itemPerRow * this.plateHeight
    },
    platesRotationDegree() {
      // высчитываем угол поворота
      return 90 - (Math.atan(1 / this.slopeParams.ratio) * 180) / Math.PI
    },
    arrowStrokeColor() {
      return this.isNav ? 'nav' : 'grey'
    },
    figureStrokeColor() {
      return this.isNav || this.isDetails ? 'var(--grey-stroke)' : 'var(--black)'
    },
    strokeWidth() {
      return this.isNav || this.isDetails ? 2 : 3
    },
    arrowDirectionStrokeWidth() {
      return this.isNav || this.isDetails ? 2 : 4
    },

    figureSvgScale() {
      return this.isNav ? this.defaultSvgParams.navScale : 1
    },
    arrowDirectionSize() {
      return this.isNav ? 2.5 : 10
    },
    arrowWidth() {
      const { changeHeightDown, changeWidthLeft, changeWidthRight, changeBias } = this
      const y = changeHeightDown + changeBias / 2 + 60
      return drawSizeArrowHorizontal(changeWidthLeft, y, changeWidthRight)
    },
    arrowDirectionRight() {
      const {
        arrowDirectionRightStartX,
        arrowDirectionRightEndX,
        arrowDirectionY,
        arrowDirectionSize
      } = this
      return drawArrowDirection(
        arrowDirectionRightStartX,
        arrowDirectionRightEndX,
        arrowDirectionY,
        arrowDirectionSize
      )
    },
    arrowDirectionLeft() {
      const {
        arrowDirectionLeftStartX,
        arrowDirectionLeftEndX,
        arrowDirectionY,
        arrowDirectionSize
      } = this
      return drawArrowDirection(
        arrowDirectionLeftStartX,
        arrowDirectionLeftEndX,
        arrowDirectionY,
        arrowDirectionSize,
        true
      )
    },
    arrowValleyOffset() {
      const { changeHeightDown, changeWidthLeft, changeBias, valleyY } = this
      const x = changeWidthLeft - 30
      const y1 = changeHeightDown + changeBias / 2
      return drawSizeArrowVertical(x, y1, valleyY)
    },
    doubleSideRectangularTrapezoidTriangleVertical() {
      const {
        defaultSvgParams,
        figureSvgScale,
        changeHeightUp,
        changeBias,
        changeWidthRight,
        changeHeightDown,
        changeWidthLeft
      } = this
      return `M${(defaultSvgParams.width * figureSvgScale) / 2},${changeHeightUp - changeBias / 2}
      L${changeWidthRight},${changeHeightDown - changeBias / 2}
      M${changeWidthRight},${changeHeightDown - changeBias / 2}
      L${changeWidthRight},${changeHeightDown + changeBias / 2}
      M${changeWidthRight},${changeHeightDown + changeBias / 2}
      L${changeWidthLeft},${changeHeightDown + changeBias / 2}
      M${changeWidthLeft},${changeHeightDown + changeBias / 2}
      L${changeWidthLeft},${changeHeightDown - changeBias / 2}
      M${changeWidthLeft},${changeHeightDown - changeBias / 2}
      L${(defaultSvgParams.width * figureSvgScale) / 2},
      ${changeHeightUp - changeBias / 2}
      M${(defaultSvgParams.width * figureSvgScale) / 2},
      ${changeHeightUp - changeBias / 2}
      L${(defaultSvgParams.width * figureSvgScale) / 2},
      ${changeHeightDown - changeBias / 2}
      M${(defaultSvgParams.width * figureSvgScale) / 2},
      ${changeHeightDown - changeBias / 2}
      L${(defaultSvgParams.width * figureSvgScale) / 2},
      ${changeHeightDown + changeBias / 2}
      M${(defaultSvgParams.width * figureSvgScale) / 2},
      ${changeHeightDown + changeBias / 2}`
    },
    arrowBiasHeight() {
      const { changeHeightDown, changeBias, changeWidthLeft } = this
      const x = changeWidthLeft - 30
      const y1 = changeHeightDown - changeBias / 2
      const y2 = changeHeightDown + changeBias / 2
      return drawSizeArrowVertical(x, y1, y2)
    },
    figureMaskRight() {
      const {
        defaultSvgParams,
        figureSvgScale,
        changeHeightUp,
        changeBias,
        changeWidthRight,
        changeHeightDown
      } = this
      return `M${(defaultSvgParams.width * figureSvgScale) / 2},${changeHeightUp - changeBias / 2}
      L${changeWidthRight},${changeHeightDown - changeBias / 2}
      L${changeWidthRight},${changeHeightDown + changeBias / 2}
      L${(defaultSvgParams.width * figureSvgScale) / 2},
      ${changeHeightDown + changeBias / 2} Z`
    },
    figureMaskLeft() {
      const {
        defaultSvgParams,
        figureSvgScale,
        changeHeightUp,
        changeBias,
        changeHeightDown,
        changeWidthLeft
      } = this
      return `M${(defaultSvgParams.width * figureSvgScale) / 2},${changeHeightUp - changeBias / 2}
      L${changeWidthLeft},${changeHeightDown - changeBias / 2}
      L${changeWidthLeft},${changeHeightDown + changeBias / 2}
      L${(defaultSvgParams.width * figureSvgScale) / 2},
      ${changeHeightDown + changeBias / 2} Z`
    },
    scaleH() {
      // коэффициент высоты
      return (
        (this.defaultSvgParams.baseHeight * this.figureSvgScale * 100) /
        (this.slopeParams.width / (this.slopeParams.ratio * 2) + this.slopeParams.bias1)
      )
    },
    scaleW() {
      // коэффициент ширины
      return (this.defaultSvgParams.baseWidth * this.figureSvgScale * 100) / this.slopeParams.width
    },
    scale() {
      // применяемый коэффициент в зависимости от того какой из коэффициентов больше
      return getMin(this.scaleW, this.scaleH)
    },
    changeWidthLeft() {
      // изменяем ширину влево
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 -
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeWidthRight() {
      // изменяем ширину вправо
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 +
        (this.slopeParams.width * this.scale) / 2
      )
    },
    changeHeightUp() {
      // изменяем высоту вверх
      return (
        (this.normalizedSchemeHeight * this.figureSvgScale) / 2 -
        (this.slopeParams.width / this.slopeParams.ratio / 4) * this.scale
      )
    },
    changeHeightDown() {
      // изменяем высоту вниз
      return (
        (this.normalizedSchemeHeight * this.figureSvgScale) / 2 +
        (this.slopeParams.width / this.slopeParams.ratio / 4) * this.scale
      )
    },
    changeBias() {
      return this.slopeParams.bias1 * this.scale
    },
    arrowDirectionRightStartX() {
      // изменяем ширину направляющей стрелки влево
      return this.arrowRightLength - this.arrowBias + this.arrowRightNewLength * 0.2
    },
    arrowDirectionRightEndX() {
      // изменяем ширину напрвляющей стрелки вправо
      return (
        this.arrowRightLength -
        this.arrowBias +
        this.arrowRightNewLength -
        this.arrowRightNewLength * 0.2
      )
    },
    arrowDirectionLeftStartX() {
      // изменяем ширину направляющей стрелки влево
      return this.arrowLeftLength + this.arrowBias - this.arrowRightNewLength * 0.2
    },
    arrowDirectionLeftEndX() {
      // изменяем ширину напрвляющей стрелки вправо
      return (
        this.arrowLeftLength +
        this.arrowBias -
        this.arrowRightNewLength +
        this.arrowRightNewLength * 0.2
      )
    },
    arrowDirectionY() {
      // изменяем положение напрвляющей стрелки по высоте
      return this.changeHeightDown - this.changeBias / 2
    },
    arrowRightLength() {
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 +
        (this.slopeParams.width * this.scale) / (this.slopeParams.ratio + 1) / 2
      )
    },
    arrowLeftLength() {
      return (
        (this.defaultSvgParams.width * this.figureSvgScale) / 2 -
        (this.slopeParams.width * this.scale) / (this.slopeParams.ratio + 1) / 2
      )
    },
    arrowG() {
      return this.arrowRightLength * Math.sqrt(2) - 20
    },
    arrowRightNewLength() {
      return (
        Math.sqrt(this.arrowRightLength ** 2 + this.arrowBias ** 2) -
        (this.defaultSvgParams.width * this.figureSvgScale) / 2
      )
    },
    arrowBias() {
      return (
        (this.slopeParams.width * this.scale) /
        (this.slopeParams.ratio + 1) /
        this.slopeParams.ratio /
        2
      )
    },
    arrowDirectionRightRotationDegree() {
      // высчитываем угол поворота направляющей
      // стрелки что бы она была перпендикулярна гипотенузе
      return (Math.atan(1 / this.slopeParams.ratio) * 180) / Math.PI - 90
    },
    arrowDirectionLeftRotationDegree() {
      // высчитываем угол поворота направляющей
      // стрелки что бы она была перпендикулярна гипотенузе
      return 90 - (Math.atan(1 / this.slopeParams.ratio) * 180) / Math.PI
    },
    cathetHeight() {
      return getCathetSize(this.slopeParams.width / 2, this.slopeParams.ratio)
    },
    normalizedSchemeHeight() {
      return this.isNav ? this.defaultSvgParams.height : this.defaultSvgParams.height + 60
    },
    valleyY() {
      const { defaultSvgParams, slopeParams, changeHeightDown, changeBias } = this
      const figureBottomY = changeHeightDown + changeBias / 2
      return getValleyY(defaultSvgParams.height, slopeParams, figureBottomY)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass"></style>
